<ngx-chat size="medium" scrollBottom="true">
  <nb-chat-message *ngFor="let rec of recs"
                   [type]="text"
                   [message]="rec.text"
                   [reply]="rec.reply == undefined ? false : rec.reply"
                   [sender]="rec.user.name"
                   [date]="rec.createDate">
  </nb-chat-message>
</ngx-chat>

<ngx-message-comment 
    [parentId]="parentId"
    [parentType]="'content'">

</ngx-message-comment>
