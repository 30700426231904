import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StompService {
  messageBay = new EventEmitter<any>();
  stompClient: any;
  messages: any[] = [];

  constructor() {
    // FIXME: Settings need to be decoupled!
    // - 'http://localhost:9010/etacms-ws', /etacms-ws,
    // - 212.52.164.223:8032/etacms-ws, 'http://212.52.164.223:8032/etacms-ws'
    const socket = new SockJS('/etacms-ws');

    this.stompClient = Stomp.over(socket);

    this.stompClient.connect({}, (frame) => {

        console.log('Connected: ' + frame);

        this.stompClient.subscribe('/topic/broadcast', (msg) => {
            const message = JSON.parse(msg.body);

            message.time = new Date();
            
            message.message = `${message.from}: ${message.text}`;
            

            this.messages.unshift(message);

            this.messageBay.emit(message);
        });
    });
  }

  sendMessage(message: any) {
    this.stompClient.send('/app/broadcast', {}, JSON.stringify(message));
  }

  getMessages() {
    return this.messages;
  }
}
