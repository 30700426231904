import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { StompService } from '../../services/stomp.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../auth.service';
import { MessageCommentService } from './message-comment.service';

@Component({
    selector: 'ngx-message-comment',
    styleUrls: ['./message-comment.component.scss'],
    templateUrl: './message-comment.component.html',
})
export class MessageCommentComponent implements OnInit {
    @Input() placeholder = 'Megjegyzés leírása...';
    @Input() caption: string = 'Új megjegyzés';
    @Input() parentId: string;
    @Input() parentType: string;

    @Input()
    set target(target: string) {
        if (!target) {
            this.target = '@ALL';
        }
    }
    name = 'content_comment';
    message = '';


    constructor(
        protected stompService: StompService,
        protected messageService: MessageCommentService,
        protected formBuilder: FormBuilder,
        protected cookieService: CookieService,
        protected authService: AuthService,
    ) {
    }

    ngOnInit() {
    }


    sendMessage() {
        const message = {
            id: 100,
            parentId: this.parentId,
            parentType: this.parentType,
            userId: this.authService.getUser().id,
            createDate: new Date(),
            text: this.message,
            target: this.target,
            source: 'linked',
            logicRemove: 0,
            user: 'api/users/' + this.authService.getUser().id,
        };
        this.messageService.saveMessage(message, this.authService.getUser().name);
        this.message = '';
    }
}
