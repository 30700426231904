import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import {NbAuthService} from "@nebular/auth";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, authService2: NbAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    return this.authService.isAuthenticated().then((response) => {
      if (!response) {
        this.authService.pushRejectedRoute(route.url);
        this.router.navigate(['/auth/login']);

        return false;
      } else if (route.url.length > 0) {
        const user = this.authService.getUser();

        const component = route.url[0].path;

        if (['user', 'site'].includes(component)) {
          return user.roleType === 'admin';
        }

        if (['project'].includes(component)) {
          return ['admin', 'approver'].includes(user.roleType);
        }

        return true;
      } else {

        return true;
      }
    });
  }

  canActivateChild = this.canActivate;
}
