<button
  class="gbutton {{status}}"
  nbButton
  (click)="onClick()"
  [nbPopover]="popOver"
>
  <!-- <nb-icon style="color: white" icon="email-unread" pack="ion"></nb-icon> -->
  <nb-icon
    class="{{status}}"
    icon="email-outline"
  ></nb-icon>
</button>
