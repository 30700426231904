import { NbDialogRef } from '@nebular/theme';
import { Component, Input } from '@angular/core';
import { ConfirmDialogService } from './confirm-dialog.service';

@Component({
  selector: 'ngx-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() title = 'Title';
  @Input() description = 'Description';

  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
  ) { }

  cancel() {
    this.ref.close({confirm: false});
  }

  submit() {
    this.ref.close({confirm: true});
  }
}
