import { StompService } from './../../../services/stomp.service';
import { MessagePopoverComponent } from './message-popover/message-popover.component';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-message',
  styleUrls: ['./message.component.scss'],
  templateUrl: './message.component.html',
})
export class MessageComponent  {
  status = '';
  popOver = MessagePopoverComponent;
  lastTime = -1;

  constructor(
    protected stompService: StompService,
  ) {
    this.stompService.messageBay.subscribe((message) => {
      this.status = 'msgNew';
    });
  }

  onClick() {
    this.status = '';
  }
}
