<div class="form-group">
  <label for="textarea{{name}}" class="label">{{caption}}</label>

  <textarea rows="5"
    id="textarea{{name}}"
    nbInput
    fullWidth
    placeholder="{{placeholder}}"
    [(ngModel)] = message
  ></textarea>

  <button
    class="gbutton float-right"
    nbButton
    status="info"
    (click)="sendMessage()"
  >Küldés<nb-icon class="button" icon="paper-plane-outline"></nb-icon></button>

</div>