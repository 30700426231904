<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    {{ description  }}
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="cancel()">Mégsem</button>
    <button nbButton class="float-right" status="success" (click)="submit()">Rendben</button>
  </nb-card-footer>
</nb-card>
