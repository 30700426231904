
<div class="p-4">
  <nb-list *ngIf="messages.length > 0">
    <nb-list-item *ngFor="let message of messages">
      {{ message.message }}
    </nb-list-item>
  </nb-list>
  <span *ngIf="messages.length < 1">
    Nincs új üzenet!
  </span>
</div>
