import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
    this.route.params.subscribe(params => {
      console.log('params', params);

      this.router.navigate([params.link.replaceAll('_', '/')]);
    });
  }

  ngOnInit(): void {
  }

}
