import { StompService } from './../../../../services/stomp.service';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-popover-form',
  templateUrl: './message-popover.component.html',
})
export class MessagePopoverComponent {
  messages: any[];

  constructor(
    protected stompService: StompService,
  ) {
    this.messages = this.stompService.getMessages();
  }
}
