import {
    NbCardModule,
    NbButtonModule,
    NbFormFieldModule,
    NbInputModule,
    NbIconModule,
    NbChatModule,
} from '@nebular/theme';
import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RedirectComponent } from './redirect/redirect.component';
import { MessageCommentComponent } from './message-comment/message-comment.component';
import { MessageCommentService } from './message-comment/message-comment.service';
import { ThemeModule } from '../@theme/theme.module';
import { FormsModule as ngFormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChatComponent } from './message-box/message-chat/message-chat.component';
import { MessageBoxComponent } from './message-box/message-box.component';


@NgModule({
    declarations: [
        ConfirmDialogComponent,
        RedirectComponent,
        MessageCommentComponent,
        NgxChatComponent,
        MessageBoxComponent,
    ],
    imports: [
        ThemeModule,
        ngFormsModule,
        ReactiveFormsModule,
        NbCardModule,
        NbInputModule,
        NbFormFieldModule,
        NbButtonModule,
        NbIconModule,
        NbChatModule,
    ],
    exports: [
        MessageCommentComponent,
        NgxChatComponent,
        MessageBoxComponent,
    ],
    providers: [
        MessageCommentService,
    ],
    entryComponents: [
        ConfirmDialogComponent,
    ],
})
export class ComponentsModule {
}
