import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { NbChatComponent } from '@nebular/theme';

@Component({
    selector: 'ngx-chat',
    template: `
      <div class="scrollable" #scrollable>
        <div class="messages">
          <ng-content select="nb-chat-message"></ng-content>
          <p class="no-messages" *ngIf="!messages?.length">{{ noMessagesPlaceholder }}</p>
        </div>
      </div>
      <div class="form">
        <ng-content select="nb-chat-form"></ng-content>
      </div>
    `,
    styleUrls: ['./message-chat.component.scss'],
})
export class NgxChatComponent extends NbChatComponent implements AfterViewChecked {
    @ViewChild('scrollable') chatWindow;

    ngAfterViewChecked() {
        $(this.chatWindow).scrollTop = $(this.chatWindow).scrollHeight;
    }
}
