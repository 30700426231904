import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { StompService } from '../../services/stomp.service';
import { DataService } from '../form-components/data.service';

@Injectable()
export class MessageCommentService {

    refresh = new EventEmitter();
    subsVar: Subscription;

    constructor(
        protected http: HttpClient,
        protected stompService: StompService,
    ) { }

    onRefresh() {
        this.refresh.emit();
    }

    saveMessage(newMessage, userName) {
        this.http.post(`/api/messages`, newMessage).subscribe((resp) => {
            this.onRefresh();
            this.stompService.sendMessage({ from: userName, text: newMessage.text , messageBody: newMessage });
        });
    }
}
