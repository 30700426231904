import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { debug } from 'console';
import { AuthService } from '../../auth.service';
import { StompService } from '../../services/stomp.service';
import { MessageCommentService } from '../message-comment/message-comment.service';



@Component({
    selector: 'ngx-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent implements OnInit, OnChanges, OnDestroy {

    recs = [];

    tempParentId: String;

    @Input() parentId: string;
    @Input() parentType: string;

    constructor(
        protected http: HttpClient,
        protected messageCommentService: MessageCommentService,
        protected authService: AuthService,
        protected stompService: StompService,
    ) {
        this.authService.userDataSet.subscribe((user) => {
            this.recs.forEach((rec) => {
                if (user.id === rec.user.id) {
                    rec.reply = true;
                    rec.user.name = 'Én';
                }
            });
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.parentId && this.parentId !== 'new' && this.parentType) {
            this.refresh();
        }
    }

    ngOnInit(): void {
        this.messageCommentService.subsVar = this.stompService.messageBay.subscribe((message) => {
            if (message.messageBody.parentId === this.parentId && message.messageBody.parentType === this.parentType) {
                this.refresh();
            }
        });
    }

    ngOnDestroy(): void {
        this.messageCommentService.subsVar.unsubscribe();
    }

    refresh() {
        // tslint:disable-next-line: max-line-length
        this.http.get(`/api/messages/filterMessages?logicRemove_equal=0&isAnd=true&parentType_equal=${this.parentType}&parentId_equal=${this.parentId}`)
            .subscribe((data: any) => {
                if (data._embedded) {
                    const sortedComments = data._embedded.messages.sort((a, b) => b.createDate - a.createDate);
                    const userId = this.authService.getUser().id;
                    sortedComments.forEach((rec) => {
                        if (userId === rec.user.id) {
                            rec.reply = true;
                            rec.user.name = 'Én';
                        }
                        if (!this.recs.map((item) => item.id).includes(rec.id)) {
                            this.recs.push(rec);
                        }
                    });
                }
            });
    }
}
