
import { Router, UrlSegment } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as bcrypt from 'bcryptjs';
import { Base64 } from 'js-base64';

@Injectable()
export class AuthService {
  // TODO: EventEmitter only for Components https://stackoverflow.com/questions/36076700/
  userDataSet = new EventEmitter();

  user: any;
  rejectedRoute: UrlSegment[];

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private http: HttpClient,
  ) {
    this.isAuthenticated().then((response) => {
      if (response) {
        this.setUser();
      }
    });
  }

  async isAuthenticated() {
    const headers_object = new HttpHeaders().set(
      'X-Requested-With',
      'XMLHttpRequest'
    );

    const httpOptions = {
      headers: headers_object,
    };

    let authenticated = false;
    await this.http
      .get('/api/isLoggedIn', httpOptions)
      .toPromise()
      .then(
        () => {
          authenticated = true;
        },
        () => {
          authenticated = false;
        }
      );

    return authenticated;
  }

  login(user): Observable<boolean> {
    const auth64 = Base64.encode(user.email + ':' + user.password);
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + auth64)
      .set('X-Requested-With', 'XMLHttpRequest');

    const httpOptions = {
      headers: headers_object,
    };

    let loginAttempt = false;
    const subject = new Subject<boolean>();
    this.http.get('/api/myuser', httpOptions).subscribe(
      (res: any) => {
        if (res.error) {
          console.log(res.error);
          subject.next(loginAttempt);
        } else {
          loginAttempt = true;
          subject.next(loginAttempt);
          this.cookieService.set('sessionId', res.sessionId, null, '/');

          this.setUser();

          setTimeout(() => {
            if (this.rejectedRoute && this.rejectedRoute.length > 1) {
                let urlString = '/pages';
                this.rejectedRoute.forEach(segment => urlString += '/' + segment);
                this.router.navigate([urlString]);
            }else{
                this.router.navigate(['/']);
            }
          }, 500);
        }
      },
      (err) => {
        subject.next(loginAttempt);
        console.log('Login error!');
        console.log(err);
        return err;
      }
    );

    return subject.asObservable();
  }

  reauth(password: string): Observable<boolean> {
    const subject = new Subject<boolean>();

    this.http.get('api/reauth', {responseType: 'text'}).subscribe(res => {
      subject.next(bcrypt.compareSync(password, res));
    });

    return subject.asObservable();
  }

  logout() {
    this.http.post('/api/logout/', {}).subscribe(
      (res: any) => {},
      (err) => {
        console.log(err);
        return err;
      }
    );

    this.cookieService.delete('sessionId', '/');
    this.rejectedRoute = undefined;
    this.router.navigate(['/auth/login']);
  }

  // If possible use userDataSet
  getUser() {
    return this.user;
  }

  setUser() {
    this.http.get('/api/myuser').subscribe(
      (res: any) => {
          this.user = res;
          this.userDataSet.emit(this.user);
      },
      (err) => {
          console.log(err);
          return err;
      },
    );
  }

  pushRejectedRoute(route: UrlSegment[]) {
      this.rejectedRoute = route;
  }
}
